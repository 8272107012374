$(document).on('click', 'table.visit-url-on-click tr', e => {
  const url = $(e.currentTarget).closest('tr').data('url');
  if (url) {
    if (e.metaKey || e.ctrlKey) {
      window.open(url, "_blank");
    } else {
      window.location.assign(url);
    }
  }
});
