import dialogConfirm from './dialogs'

$(document).on('confirm', e => {
  const $link = $(e.target);

  const text = $link.data('confirm');
  const okText = $link.data('dialog-ok') || 'OK';
  const cancelText = $link.data('dialog-cancel') || 'Cancel';

  dialogConfirm(text, {okText, cancelText})
    .then(answer => {
      if (answer) {
        $link.data('confirm', null);
        if ($link.prop('tagName') === 'FORM') {
          $link.submit();
        } else {
          $link.trigger('click.rails');
        }
      }
    });

  return false; // So rails doesn't display a browser confirm
});


