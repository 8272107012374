$(document).on("DOMContentLoaded", function() {
  $(".pronoun-request input[type=text]").on('click', () => {
    $("#student_pronouns_selector_freeform").prop('checked', true);
  });

  const $pronouns = $("#student_freeform_pronouns");
  $pronouns.on('input', () => {
    const cleaned = $pronouns.val()
      .toLowerCase()
      .replace(/^\//, '')
      .replace(/ \/($|\S)/g, '')
      .replace(/[^a-z\/]/g, '')
      .replace(/\//g, " / ");
    $pronouns.val(cleaned);
  });
});
