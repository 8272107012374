// Promise polyfill for IE11
require('promis');

import "./jquery_global";

import {} from 'jquery-ujs';

import "./action_replace_links";
import "./confirm_hook";
import "./flash";
import "./clickable_tables";
import "./pronouns";

jQuery(document).on("DOMContentLoaded", function() {
  if ("ActiveXObject" in window) {
    $("#ie-check").prop('hidden', false);
  }
});
