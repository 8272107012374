import $ from 'jquery';

$(document).on('click', 'header.flash-message .close', function() {
  let el = $(this).closest('.flash-message');
  el.remove();
});

$(document).on('keydown', e => {
  if (e.which === 27) {
    $('header.flash-message').remove();
  }
});
